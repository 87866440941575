import React, { useState, useRef, useEffect } from "react";
import {
  TimeEntry,
  Project,
  Client,
  TimeEntryStatus,
  Tag,
  TimeEntryTag,
} from "@/models";
import { ChevronDown, ChevronUp, MoreHorizontal, Plus, X } from "lucide-react";

interface RecentEntriesTableProps {
  entries: TimeEntry[];
  projects: Project[];
  clients: Client[];
  tags: Tag[];
  timeEntryTags: TimeEntryTag[];
  onDelete: (id: string) => void;
  onEdit: (
    id: string,
    updatedEntry: Partial<TimeEntry>,
    updatedTags: string[]
  ) => void;
  onAdd: (newEntry: Omit<TimeEntry, "id">, newTags: string[]) => void;
}

export function RecentEntriesTable({
  entries,
  projects,
  clients,
  tags,
  timeEntryTags,
  onDelete,
  onEdit,
  onAdd,
}: RecentEntriesTableProps) {
  const [editingEntry, setEditingEntry] = useState<TimeEntry | null>(null);
  const [editingTags, setEditingTags] = useState<string[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [sorting, setSorting] = useState<{
    key: keyof TimeEntry;
    direction: "asc" | "desc";
  }>({ key: "start_time", direction: "desc" });
  const [newEntry, setNewEntry] = useState<Omit<TimeEntry, "id">>({
    user_id: "",
    project_id: "",
    description: "",
    start_time: "",
    end_time: "",
    hourly_rate: 0,
    status: TimeEntryStatus.Completed,
    created_at: new Date().toISOString(),
  });
  const [newTags, setNewTags] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [overrideRate, setOverrideRate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [tagInput, setTagInput] = useState<string>("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const tagInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedClient) {
      const clientProjects = projects.filter(
        (p) => p.client_id === selectedClient
      );
      if (clientProjects.length > 0) {
        setNewEntry((prev) => ({ ...prev, project_id: clientProjects[0].id }));
      }
    }
  }, [selectedClient, projects]);

  const ExpandableCell = ({ value }: { value: string }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
      <div>
        {isExpanded ? value : value.slice(0, 60)}
        {value.length > 60 && (
          <button className="btn btn-ghost btn-xs" onClick={toggleExpand}>
            {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          </button>
        )}
      </div>
    );
  };

  const formatDuration = (start: string, end?: string): string => {
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : new Date();
    const durationInSeconds = Math.floor(
      (endDate.getTime() - startDate.getTime()) / 1000
    );
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return `${hours}:${minutes.toString().padStart(2, "0")}`;
  };

  const getProjectName = (projectId: string): string => {
    return projects.find((p) => p.id === projectId)?.name || "Unknown Project";
  };

  const getClientName = (projectId: string): string => {
    const project = projects.find((p) => p.id === projectId);
    if (project) {
      return (
        clients.find((c) => c.id === project.client_id)?.name ||
        "Unknown Client"
      );
    }
    return "Unknown Client";
  };

  const getEntryTags = (entryId: string): string[] => {
    const entryTagIds = timeEntryTags
      .filter((tet) => tet.time_entry_id === entryId)
      .map((tet) => tet.tag_id);
    return tags
      .filter((tag) => entryTagIds.includes(tag.id))
      .map((tag) => tag.name);
  };

  const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (editingEntry) {
      onEdit(editingEntry.id, editingEntry, editingTags);
      setEditingEntry(null);
      setEditingTags([]);
      setIsEditModalOpen(false);
    }
  };

  const handleAddSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onAdd(newEntry, newTags);
    setIsAddModalOpen(false);
    setNewEntry({
      user_id: "",
      project_id: "",
      description: "",
      start_time: "",
      end_time: "",
      hourly_rate: 0,
      status: TimeEntryStatus.Completed,
      created_at: new Date().toISOString(),
    });
    setNewTags([]);
    setSelectedClient("");
    setOverrideRate(false);
  };

  const formatDateTimeForInput = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16);
  };

  const handleTagInput = (value: string) => {
    if (!value.startsWith("#")) {
      value = "#" + value;
    }
    setTagInput(value);
    setShowSuggestions(true);
  };

  const handleTagKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && tagInput.trim() !== "#") {
      event.preventDefault();
      const newTag = tagInput.trim();
      if (!editingTags.includes(newTag)) {
        setEditingTags([...editingTags, newTag]);
        setTagInput("");
      }
      setShowSuggestions(false);
    }
  };

  const removeTag = (tagToRemove: string) => {
    setEditingTags(editingTags.filter((tag) => tag !== tagToRemove));
  };

  const handleTagSuggestionClick = (tagName: string) => {
    if (!editingTags.includes(tagName)) {
      setEditingTags([...editingTags, tagName]);
    }
    setTagInput("");
    setShowSuggestions(false);
    tagInputRef.current?.focus();
  };

  const sortedEntries = [...entries].sort((a, b) => {
    // if(!a || !b || !a[sorting.key] || !b[sorting.key]) return 0;
    if ((a[sorting.key] ?? "") < (b[sorting.key] ?? ""))
      return sorting.direction === "asc" ? -1 : 1;
    if ((a[sorting.key] ?? "") > (b[sorting.key] ?? ""))
      return sorting.direction === "asc" ? 1 : -1;
    return 0;
  });

  const paginatedEntries = sortedEntries.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="card-title">Recent Time Entries</h2>
            <p className="text-base-content/70">Your latest logged hours</p>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => setIsAddModalOpen(true)}
          >
            <Plus size={16} className="mr-2" /> Add Entry
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="table w-full">
            <thead>
              <tr>
                <th
                  onClick={() =>
                    setSorting({
                      key: "start_time",
                      direction: sorting.direction === "asc" ? "desc" : "asc",
                    })
                  }
                >
                  Date{" "}
                  {sorting.key === "start_time" &&
                    (sorting.direction === "asc" ? "▲" : "▼")}
                </th>
                <th>Duration</th>
                <th>Project</th>
                <th>Client</th>
                <th>Rate</th>
                <th>Description</th>
                <th>Tags</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedEntries.map((entry) => (
                <tr key={entry.id}>
                  <td>{new Date(entry.start_time).toLocaleDateString()}</td>
                  <td>{formatDuration(entry.start_time, entry.end_time)}</td>
                  <td>{getProjectName(entry.project_id)}</td>
                  <td>{getClientName(entry.project_id)}</td>
                  <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(entry.hourly_rate ?? 0)}
                    /hr
                  </td>
                  <td>
                    <ExpandableCell value={entry.description ?? ""} />
                  </td>
                  <td>
                    <div className="flex flex-wrap gap-1">
                      {getEntryTags(entry.id).map((tag, index) => (
                        <span key={index} className="badge badge-primary">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td>
                    <div className="dropdown dropdown-end">
                      <label tabIndex={0} className="btn btn-ghost btn-xs">
                        <MoreHorizontal size={16} />
                      </label>
                      <ul
                        tabIndex={0}
                        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                      >
                        <li>
                          <a
                            onClick={() => {
                              setEditingEntry(entry);
                              setEditingTags(getEntryTags(entry.id));
                              setIsEditModalOpen(true);
                            }}
                          >
                            Edit
                          </a>
                        </li>
                        <li>
                          <a onClick={() => onDelete(entry.id)}>Delete</a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <span className="text-sm text-base-content/70">
            Showing {(currentPage - 1) * pageSize + 1} to{" "}
            {Math.min(currentPage * pageSize, entries.length)} of{" "}
            {entries.length} entries
          </span>
          <div className="btn-group">
            <button
              className="btn btn-outline btn-sm"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="btn btn-outline btn-sm"
              onClick={() =>
                setCurrentPage((prev) =>
                  Math.min(prev + 1, Math.ceil(entries.length / pageSize))
                )
              }
              disabled={currentPage === Math.ceil(entries.length / pageSize)}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      <dialog
        id="edit_modal"
        className={`modal ${isEditModalOpen ? "modal-open" : ""}`}
      >
        <div className="modal-box">
          <h3 className="font-bold text-lg">Edit Time Entry</h3>
          <form onSubmit={handleEditSubmit} className="space-y-4">
            <div className="form-control">
              <label className="label" htmlFor="edit-project">
                <span className="label-text">Project</span>
              </label>
              <select
                id="edit-project"
                className="select select-bordered w-full"
                value={editingEntry?.project_id || ""}
                onChange={(e) =>
                  setEditingEntry((prev) =>
                    prev ? { ...prev, project_id: e.target.value } : null
                  )
                }
              >
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-control">
              <label className="label" htmlFor="edit-description">
                <span className="label-text">Description</span>
              </label>
              <textarea
                id="edit-description"
                className="textarea textarea-bordered h-24"
                value={editingEntry?.description || ""}
                onChange={(e) =>
                  setEditingEntry((prev) =>
                    prev ? { ...prev, description: e.target.value } : null
                  )
                }
              ></textarea>
            </div>
            <div className="form-control">
              <label className="label" htmlFor="edit-hourly-rate">
                <span className="label-text">Hourly Rate</span>
              </label>
              <input
                id="edit-hourly-rate"
                type="number"
                className="input input-bordered"
                value={editingEntry?.hourly_rate || 0}
                onChange={(e) =>
                  setEditingEntry((prev) =>
                    prev
                      ? { ...prev, hourly_rate: parseFloat(e.target.value) }
                      : null
                  )
                }
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="edit-start-time">
                <span className="label-text">Start Time</span>
              </label>
              <input
                id="edit-start-time"
                type="datetime-local"
                className="input input-bordered"
                value={
                  editingEntry
                    ? formatDateTimeForInput(editingEntry.start_time)
                    : ""
                }
                onChange={(e) =>
                  setEditingEntry((prev) =>
                    prev ? { ...prev, start_time: e.target.value } : null
                  )
                }
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="edit-end-time">
                <span className="label-text">End Time</span>
              </label>
              <input
                id="edit-end-time"
                type="datetime-local"
                className="input input-bordered"
                value={
                  editingEntry && editingEntry.end_time
                    ? formatDateTimeForInput(editingEntry.end_time)
                    : ""
                }
                onChange={(e) =>
                  setEditingEntry((prev) =>
                    prev
                      ? { ...prev, end_time: e.target.value || undefined }
                      : null
                  )
                }
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="edit-tags">
                <span className="label-text">Tags</span>
              </label>
              <div className="flex flex-wrap gap-2 mb-2">
                {editingTags.map((tag, index) => (
                  <div key={index} className="badge badge-primary gap-2">
                    {tag}
                    <button
                      type="button"
                      className="btn btn-ghost btn-xs"
                      onClick={() => removeTag(tag)}
                    >
                      <X size={12} />
                    </button>
                  </div>
                ))}
              </div>
              <input
                id="edit-tags"
                ref={tagInputRef}
                className="input input-bordered"
                placeholder="Add tags (e.g., #design)"
                value={tagInput}
                onChange={(e) => handleTagInput(e.target.value)}
                onKeyDown={handleTagKeyDown}
              />
              {showSuggestions && (
                <ul className="menu bg-base-200 w-full rounded-box mt-2">
                  {tags
                    .filter((tag) =>
                      tag.name.toLowerCase().includes(tagInput.toLowerCase())
                    )
                    .map((tag) => (
                      <li key={tag.id}>
                        <a onClick={() => handleTagSuggestionClick(tag.name)}>
                          {tag.name}
                        </a>
                      </li>
                    ))}
                </ul>
              )}
            </div>
            <div className="modal-action">
              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
              <button
                type="button"
                className="btn"
                onClick={() => setIsEditModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </dialog>

      {/* Add Modal */}
      <dialog
        id="add_modal"
        className={`modal ${isAddModalOpen ? "modal-open" : ""}`}
      >
        <div className="modal-box">
          <h3 className="font-bold text-lg">Add New Time Entry</h3>
          <form onSubmit={handleAddSubmit} className="space-y-4">
            <div className="form-control">
              <label className="label" htmlFor="add-client">
                <span className="label-text">Client</span>
              </label>
              <select
                id="add-client"
                className="select select-bordered w-full"
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                <option value="">Select a client</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-control">
              <label className="label" htmlFor="add-project">
                <span className="label-text">Project</span>
              </label>
              <select
                id="add-project"
                className="select select-bordered w-full"
                value={newEntry.project_id}
                onChange={(e) =>
                  setNewEntry((prev) => ({
                    ...prev,
                    project_id: e.target.value,
                  }))
                }
              >
                <option value="">Select a project</option>
                {projects
                  .filter((project) => project.client_id === selectedClient)
                  .map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-control">
              <label className="label" htmlFor="add-description">
                <span className="label-text">Description</span>
              </label>
              <textarea
                id="add-description"
                className="textarea textarea-bordered h-24"
                value={newEntry.description}
                onChange={(e) =>
                  setNewEntry((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              ></textarea>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Override hourly rate</span>
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={overrideRate}
                  onChange={(e) => setOverrideRate(e.target.checked)}
                />
              </label>
            </div>
            {overrideRate && (
              <div className="form-control">
                <label className="label" htmlFor="add-hourly-rate">
                  <span className="label-text">Hourly Rate</span>
                </label>
                <input
                  id="add-hourly-rate"
                  type="number"
                  className="input input-bordered"
                  value={newEntry.hourly_rate || ""}
                  onChange={(e) =>
                    setNewEntry((prev) => ({
                      ...prev,
                      hourly_rate: parseFloat(e.target.value),
                    }))
                  }
                />
              </div>
            )}
            <div className="form-control">
              <label className="label" htmlFor="add-start-time">
                <span className="label-text">Start Time</span>
              </label>
              <input
                id="add-start-time"
                type="datetime-local"
                className="input input-bordered"
                value={newEntry.start_time}
                onChange={(e) =>
                  setNewEntry((prev) => ({
                    ...prev,
                    start_time: e.target.value,
                  }))
                }
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="add-end-time">
                <span className="label-text">End Time</span>
              </label>
              <input
                id="add-end-time"
                type="datetime-local"
                className="input input-bordered"
                value={newEntry.end_time || ""}
                onChange={(e) =>
                  setNewEntry((prev) => ({
                    ...prev,
                    end_time: e.target.value || undefined,
                  }))
                }
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="add-tags">
                <span className="label-text">Tags</span>
              </label>
              <div className="flex flex-wrap gap-2 mb-2">
                {newTags.map((tag, index) => (
                  <div key={index} className="badge badge-primary gap-2">
                    {tag}
                    <button
                      type="button"
                      className="btn btn-ghost btn-xs"
                      onClick={() =>
                        setNewTags(newTags.filter((t) => t !== tag))
                      }
                    >
                      <X size={12} />
                    </button>
                  </div>
                ))}
              </div>
              <input
                id="add-tags"
                className="input input-bordered"
                placeholder="Add tags (e.g., #design)"
                value={tagInput}
                onChange={(e) => handleTagInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && tagInput.trim() !== "#") {
                    e.preventDefault();
                    const newTag = tagInput.trim();
                    if (!newTags.includes(newTag)) {
                      setNewTags([...newTags, newTag]);
                      setTagInput("");
                    }
                  }
                }}
              />
              {showSuggestions && (
                <ul className="menu bg-base-200 w-full rounded-box mt-2">
                  {tags
                    .filter((tag) =>
                      tag.name.toLowerCase().includes(tagInput.toLowerCase())
                    )
                    .map((tag) => (
                      <li key={tag.id}>
                        <a
                          onClick={() => {
                            if (!newTags.includes(tag.name)) {
                              setNewTags([...newTags, tag.name]);
                            }
                            setTagInput("");
                            setShowSuggestions(false);
                          }}
                        >
                          {tag.name}
                        </a>
                      </li>
                    ))}
                </ul>
              )}
            </div>
            <div className="modal-action">
              <button type="submit" className="btn btn-primary">
                Add Entry
              </button>
              <button
                type="button"
                className="btn"
                onClick={() => setIsAddModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  );
}
