import { useState, useEffect } from "react";
import { createClerkSupabaseClient } from "@/App";
import { useUser } from "@clerk/clerk-react";
import { TimeTrackingForm } from "./TimeTrackingForm";
import { SummaryCard } from "./SummaryCard";
import { RecentEntriesTable } from "./RecentEntriesTable";
import { Loader2 } from "lucide-react";
import {
  Client,
  Project,
  TimeEntry,
  TimeEntryStatus,
  Tag,
  UserPreferences,
  TimeEntryTag,
} from "@/models";
import { useToast } from "@/hooks/use-toast";
import { OnboardingDialog } from "./OnboardingDialog";
import { useUserPreferences } from "../contexts/UserPreferencesContext";

type SummaryPeriod = "today" | "week" | "month" | "year" | "custom";

interface SummaryData {
  hours: string;
  clients: number;
  projects: number;
  earnings: number;
}

export default function Dashboard() {
  const { user } = useUser();
  const [projects, setProjects] = useState<Project[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [timeEntries, setTimeEntries] = useState<TimeEntry[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [timeEntryTags, setTimeEntryTags] = useState<TimeEntryTag[]>([]);
  const { userPreferences, setUserPreferences } = useUserPreferences();

  const [loading, setLoading] = useState(true);
  const [currentTracking, setCurrentTracking] = useState<TimeEntry | null>(
    null
  );
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [summaryUpdateTrigger, setSummaryUpdateTrigger] = useState(0);
  const { toast } = useToast();

  const supabase = createClerkSupabaseClient();

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  async function fetchData() {
    setLoading(true);
    const [
      projectsData,
      clientsData,
      timeEntriesData,
      tagsData,
      timeEntryTagsData,
      preferencesData,
    ] = await Promise.all([
      supabase.from("projects").select("*").eq("user_id", user?.id),
      supabase.from("clients").select("*").eq("user_id", user?.id),
      supabase
        .from("time_entries")
        .select("*")
        .eq("user_id", user?.id)
        .order("start_time", { ascending: false }),
      supabase.from("tags").select("*").eq("user_id", user?.id),
      supabase.from("time_entry_tags").select("*").eq("user_id", user?.id),
      supabase
        .from("user_preferences")
        .select("*")
        .eq("user_id", user?.id)
        .single(),
    ]);

    if (projectsData.data) setProjects(projectsData.data as Project[]);
    if (clientsData.data) setClients(clientsData.data as Client[]);
    if (timeEntriesData.data)
      setTimeEntries(timeEntriesData.data as TimeEntry[]);
    if (tagsData.data) setTags(tagsData.data as Tag[]);
    if (timeEntryTagsData.data)
      setTimeEntryTags(timeEntryTagsData.data as TimeEntryTag[]);

    if (preferencesData.data) {
      setUserPreferences(preferencesData.data as UserPreferences);
    } else {
      // If no preferences are found, show the onboarding dialog
      setShowOnboarding(true);
    }

    setLoading(false);
  }

  async function startTracking(timeEntry: TimeEntry) {
    console.log("startTracking", timeEntry);

    const project = projects.find((p) => p.id === timeEntry.project_id);
    if (!project) return;

    setCurrentTracking(timeEntry);
    setTimeEntries([timeEntry, ...timeEntries]);
  }

  async function stopTracking() {
    console.log("stopTracking", currentTracking);
    if (currentTracking) {
      const endTime = new Date().toISOString(); // Store in UTC

      const updatedTimeEntry: Partial<TimeEntry> = {
        end_time: endTime,
        status: TimeEntryStatus.Completed,
      };

      const { data, error } = await supabase
        .from("time_entries")
        .update(updatedTimeEntry)
        .eq("id", currentTracking.id)
        .select();

      if (error) {
        console.error("Error updating time entry:", error);
        return;
      }

      if (data && data.length > 0) {
        setTimeEntries(
          timeEntries.map((te) =>
            te.id === data[0].id ? (data[0] as TimeEntry) : te
          )
        );
        setCurrentTracking(null);
        // fetchData();
        // Trigger summary update
        setSummaryUpdateTrigger((prev) => prev + 1);
      } else {
        console.error("No data returned from update query");
      }
    }
  }

  const handleAddEntry = async (newEntry: Omit<TimeEntry, "id">) => {
    try {
      const newEntryWithUserId = { ...newEntry, user_id: user?.id };

      // Perform the insert operation
      const { error } = await supabase
        .from("time_entries")
        .insert(newEntryWithUserId);

      if (error) throw error;

      // Fetch the newly inserted entry
      const { data: insertedData, error: fetchError } = await supabase
        .from("time_entries")
        .select("*")
        .eq("user_id", user?.id)
        .eq("start_time", newEntryWithUserId.start_time)
        .single();

      if (fetchError) throw fetchError;

      if (insertedData) {
        // Update the local state
        setTimeEntries((entries) => [insertedData as TimeEntry, ...entries]);

        // Trigger summary update
        setSummaryUpdateTrigger((prev) => prev + 1);
        // Show success toast
        toast({
          title: "Time entry added",
          description: "Your time entry has been successfully added.",
        });
      } else {
        throw new Error("No data returned after insertion");
      }
    } catch (error) {
      console.error("Error adding time entry:", error);
      // Show error toast
      toast({
        title: "Error adding time entry",
        description:
          "There was a problem adding your time entry. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleEditEntry = async (
    timeEntryId: string,
    updatedEntry: Partial<TimeEntry>,
    updatedTagNames: string[]
  ) => {
    try {
      // 1. Update the time entry
      const { error: updateError } = await supabase
        .from("time_entries")
        .update(updatedEntry)
        .eq("id", timeEntryId)
        .single();

      if (updateError) throw updateError;

      // 2. Retrieve existing time entry tags and associated tags
      const existingTimeEntryTags = timeEntryTags.filter(
        (tet) => tet.time_entry_id === timeEntryId
      );
      const existingTags = tags.filter((tag) =>
        existingTimeEntryTags.some((tet) => tet.tag_id === tag.id)
      );

      // 3. Determine tags to add and delete
      const tagsToDelete = existingTags.filter(
        (tag) => !updatedTagNames.includes(tag.name)
      );
      const tagsToAdd = updatedTagNames.filter(
        (tagName) => !existingTags.some((tag) => tag.name === tagName)
      );

      // 4. Delete tags that are no longer associated with the entry
      if (tagsToDelete.length > 0) {
        const { error: deleteError } = await supabase
          .from("time_entry_tags")
          .delete()
          .in(
            "tag_id",
            tagsToDelete.map((tag) => tag.id)
          )
          .eq("time_entry_id", timeEntryId);

        if (deleteError) throw deleteError;
      }

      // 5. Insert new tags (batch insert)
      const newTagsToInsert = tagsToAdd
        .map((tagName) => {
          const tagId = tags.find((tag) => tag.name === tagName)?.id;
          if (!tagId) return null;
          return {
            time_entry_id: timeEntryId,
            tag_id: tagId,
            user_id: user?.id,
          };
        })
        .filter(Boolean) as TimeEntryTag[];

      if (newTagsToInsert.length > 0) {
        const { error: insertError } = await supabase
          .from("time_entry_tags")
          .insert(newTagsToInsert);

        if (insertError) throw insertError;
      }

      // 6. Update local state optimistically after all operations succeed
      setTimeEntries((entries) =>
        entries.map((entry) =>
          entry.id === timeEntryId ? { ...entry, ...updatedEntry } : entry
        )
      );

      // TODO this is still wrongly implemented
      setTimeEntryTags((currentTags) => [
        // Keep the existing tags that were not deleted
        ...currentTags.filter(
          (tag) => !tagsToDelete.some((td) => td.id === tag.tag_id)
        ),
        // Add new time entry tags
        ...newTagsToInsert,
      ]);
      // Show success toast
      toast({
        title: "Time entry updated",
        description: "Your time entry has been successfully updated.",
      });
    } catch (error) {
      console.error("Error updating time entry:", error);
      // Show error toast
      toast({
        title: "Error updating time entry",
        description:
          "There was a problem updating your time entry. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleDeleteEntry = async (id: string) => {
    try {
      const { error } = await supabase
        .from("time_entries")
        .delete()
        .eq("id", id);

      if (error) throw error;

      // Update the local state
      setTimeEntries((entries) => entries.filter((entry) => entry.id !== id));
      // Trigger summary update
      setSummaryUpdateTrigger((prev) => prev + 1);
      // Show success toast
      toast({
        title: "Time entry deleted",
        description: "Your time entry has been successfully deleted.",
      });
    } catch (error) {
      console.error("Error deleting time entry:", error);

      // Show error toast
      toast({
        title: "Error deleting time entry",
        description:
          "There was a problem deleting your time entry. Please try again.",
        variant: "destructive",
      });
    }
  };

  const getSummaryData = async (
    period: SummaryPeriod
  ): Promise<SummaryData> => {
    let startDate: Date;
    // Always show data relevant for this full day
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    switch (period) {
      case "today":
        startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        break;
      case "week":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        break;
      case "month":
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case "year":
        startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
      default:
        startDate = new Date(0); // Beginning of time
    }

    const { data, error } = await supabase
      .from("time_entries")
      .select("*, projects(client_id)")
      .gte("start_time", startDate.toISOString())
      .lte("start_time", endDate.toISOString())
      .eq("user_id", user?.id);

    if (error) {
      console.error("Error fetching summary data:", error);
      return { hours: "0:00", clients: 0, projects: 0, earnings: 0 };
    }

    const entries = data as (TimeEntry & { projects: { client_id: string } })[];

    const totalSeconds = entries.reduce((sum, entry) => {
      const endTime = entry.end_time ? new Date(entry.end_time) : new Date();
      const duration =
        (endTime.getTime() - new Date(entry.start_time).getTime()) / 1000;
      return sum + duration;
    }, 0);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const earnings = entries.reduce((sum, entry) => {
      const endTime = entry.end_time ? new Date(entry.end_time) : new Date();
      const duration =
        (endTime.getTime() - new Date(entry.start_time).getTime()) / 3600000; // hours
      return sum + duration * (entry.hourly_rate || 0);
    }, 0);

    const uniqueClients = new Set(
      entries.map((entry) => entry.projects.client_id)
    ).size;
    const uniqueProjects = new Set(entries.map((entry) => entry.project_id))
      .size;

    const summaryData = {
      hours: `${hours}:${minutes.toString().padStart(2, "0")}`,
      clients: uniqueClients,
      projects: uniqueProjects,
      earnings: Math.round(earnings * 100) / 100,
    };

    return summaryData;
  };

  const handleOnboardingComplete = async (
    preferences: UserPreferences | null
  ) => {
    if (!preferences) {
      setShowOnboarding(false);
      return;
    }
    try {
      const newPreferences = {
        ...preferences,
        user_id: user?.id,
      };

      const { error } = await supabase
        .from("user_preferences")
        .insert(newPreferences)
        .single();

      if (error) throw error;

      const { data: insertedData, error: fetchError } = await supabase
        .from("user_preferences")
        .select("*")
        .single();

      if (fetchError) throw fetchError;

      setUserPreferences(insertedData);
      setShowOnboarding(false);

      toast({
        title: insertedData.name
          ? `Welcome to chronos, ${insertedData.name}!`
          : "Welcome to chronos!",
        description: "Your preferences have been saved successfully.",
      });
    } catch (error) {
      console.error("Error saving user preferences:", error);
      toast({
        title: "Error saving preferences",
        description:
          "There was a problem saving your preferences. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 mt-4 sm:mt-6">
        {userPreferences?.name
          ? `Hi there, ${userPreferences.name} 👋!`
          : "Hi there!"}
      </h1>

      <div className="grid gap-4 sm:gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <div className="col-span-1 md:col-span-2 lg:col-span-1">
          <TimeTrackingForm
            clients={clients}
            projects={projects}
            userPreferences={userPreferences}
            onStartTracking={startTracking}
            onStopTracking={stopTracking}
            currentTracking={currentTracking}
          />
        </div>
        <div className="col-span-1 md:col-span-2 lg:col-span-1">
          <SummaryCard
            getSummaryData={getSummaryData}
            updateTrigger={summaryUpdateTrigger}
          />
        </div>
        <div className="col-span-1 md:col-span-2 lg:col-span-2">
          <RecentEntriesTable
            entries={timeEntries}
            projects={projects}
            clients={clients}
            tags={tags}
            timeEntryTags={timeEntryTags}
            onDelete={handleDeleteEntry}
            onEdit={handleEditEntry}
            onAdd={handleAddEntry}
          />
        </div>
      </div>

      <OnboardingDialog
        isOpen={showOnboarding}
        onClose={handleOnboardingComplete}
      />
    </div>
  );
}
