import React, { useState, useEffect, useRef } from "react";
import { Play, Square, X } from "lucide-react";
import {
  Client,
  Project,
  TimeEntry,
  UserPreferences,
  Tag,
  TimeEntryTag,
  TimeEntryStatus,
} from "@/models";
import { createClerkSupabaseClient } from "@/App";
import { useUser } from "@clerk/clerk-react";
import { useToast } from "@/hooks/use-toast";

interface TimeTrackingFormProps {
  clients: Client[];
  projects: Project[];
  userPreferences: UserPreferences | null;
  onStartTracking: (timeEntry: TimeEntry) => void;
  onStopTracking: (duration: number) => void;
  currentTracking: TimeEntry | null;
}

export function TimeTrackingForm({
  clients,
  projects,
  userPreferences,
  onStartTracking,
  onStopTracking,
  currentTracking,
}: TimeTrackingFormProps) {
  const [selectedClient, setSelectedClient] = useState<string>("");
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [hourlyRate, setHourlyRate] = useState<number>(
    userPreferences?.default_hourly_rate || 0
  );
  const [overrideRate, setOverrideRate] = useState<boolean>(false);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [tags, setTags] = useState<string[]>([]);
  const [tagInput, setTagInput] = useState<string>("");
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  const { user } = useUser();
  const supabase = createClerkSupabaseClient();
  const tagInputRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast();

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (currentTracking) {
      interval = setInterval(() => {
        const now = new Date();
        const start = new Date(currentTracking.start_time);
        const elapsedTime = Math.floor(
          (now.getTime() - start.getTime()) / 1000
        );

        setElapsedTime(elapsedTime);
      }, 1000);
    } else {
      setElapsedTime(0);
    }

    return () => clearInterval(interval);
  }, [currentTracking]);

  useEffect(() => {
    if (selectedClient) {
      const clientProjects = projects.filter(
        (p) => p.client_id === selectedClient
      );
      if (clientProjects.length > 0) {
        setSelectedProject(clientProjects[0].id);
      } else {
        setSelectedProject("");
      }
    } else {
      setSelectedProject("");
    }
    setHourlyRate(userPreferences?.default_hourly_rate || 0);
  }, [selectedClient, projects, userPreferences]);

  const fetchTags = async () => {
    if (user) {
      const { data, error } = await supabase
        .from("tags")
        .select("*")
        .eq("user_id", user.id);

      if (error) {
        console.error("Error fetching tags:", error);
      } else {
        setAllTags(data || []);
      }
    }
  };

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleStartStop = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (currentTracking) {
      onStopTracking(elapsedTime);
    } else {
      if (!user) {
        toast({
          title: "Error",
          description: "User not found. Please log in again.",
          variant: "destructive",
        });
        return;
      }

      const newTimeEntry: TimeEntry = {
        id: crypto.randomUUID(),
        user_id: user.id,
        project_id: selectedProject,
        description: description,
        start_time: new Date().toISOString(),
        hourly_rate: hourlyRate,
        status: TimeEntryStatus.InProgress,
        created_at: new Date().toISOString(),
      };

      const { data: timeEntryData, error: timeEntryError } = await supabase
        .from("time_entries")
        .insert(newTimeEntry)
        .select();

      if (timeEntryError) {
        console.error("Error creating time entry:", timeEntryError);
        toast({
          title: "Error",
          description: "Failed to start time tracking. Please try again.",
          variant: "destructive",
        });
        return;
      }
      onStartTracking(newTimeEntry);

      if (timeEntryData && timeEntryData.length > 0) {
        const timeEntryId = timeEntryData[0].id;

        await Promise.all(
          tags.map(async (tagName) => {
            let tagId: string;

            const { data: existingTags, error: tagError } = await supabase
              .from("tags")
              .select("id")
              .eq("user_id", user.id)
              .eq("name", tagName)
              .limit(1);

            if (tagError) {
              console.error("Error checking existing tag:", tagError);
              return;
            }

            if (existingTags && existingTags.length > 0) {
              tagId = existingTags[0].id;
            } else {
              const { data: newTag, error: newTagError } = await supabase
                .from("tags")
                .insert({ user_id: user.id, name: tagName })
                .select();

              if (newTagError || !newTag || newTag.length === 0) {
                console.error("Error creating new tag:", newTagError);
                return;
              }

              tagId = newTag[0].id;
            }

            const timeEntryTag: TimeEntryTag = {
              time_entry_id: timeEntryId,
              tag_id: tagId,
              user_id: user.id,
            };

            const { error: timeEntryTagError } = await supabase
              .from("time_entry_tags")
              .insert(timeEntryTag);

            if (timeEntryTagError) {
              console.error(
                "Error associating tag with time entry:",
                timeEntryTagError
              );
            }
          })
        );

        toast({
          title: "Success",
          description: "Time tracking started successfully.",
        });
      }
    }
  };

  const handleTagInput = (value: string) => {
    if (!value.startsWith("#")) {
      value = "#" + value;
    }
    setTagInput(value);
    setShowSuggestions(true);
  };

  const handleTagKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && tagInput.trim() !== "#") {
      event.preventDefault();
      const newTag = tagInput.trim();
      if (!tags.includes(newTag)) {
        setTags([...tags, newTag]);
        setTagInput("");

        const existingTag = allTags.find((t) => t.name === newTag);
        if (!existingTag && user) {
          const { data, error } = await supabase
            .from("tags")
            .insert({ user_id: user.id, name: newTag })
            .select();

          if (error) {
            console.error("Error creating new tag:", error);
          } else if (data) {
            setAllTags([...allTags, data[0]]);
          }
        }
      }
      setShowSuggestions(false);
    }
  };

  const removeTag = (tagToRemove: string) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleTagSuggestionClick = (tagName: string) => {
    if (!tags.includes(tagName)) {
      setTags([...tags, tagName]);
    }
    setTagInput("");
    setShowSuggestions(false);
    tagInputRef.current?.focus();
  };

  const filteredProjects = projects.filter(
    (p) => p.client_id === selectedClient
  );

  const filteredTags = allTags.filter((tag) =>
    tag.name.toLowerCase().includes(tagInput.toLowerCase())
  );

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">Time Tracking</h2>
        <p className="text-base-content/70">Start tracking or log your time</p>
        <form className="space-y-4">
          <div className="form-control">
            <label className="label" htmlFor="client">
              <span className="label-text">Client</span>
            </label>
            <select
              id="client"
              className="select select-bordered w-full"
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)}
              disabled={!!currentTracking}
            >
              <option value="">Select a client</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-control">
            <label className="label" htmlFor="project">
              <span className="label-text">Project</span>
            </label>
            <select
              id="project"
              className="select select-bordered w-full"
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
              disabled={!!currentTracking || !selectedClient}
            >
              <option value="">Select a project</option>
              {filteredProjects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-control">
            <label className="label" htmlFor="description">
              <span className="label-text">Description</span>
            </label>
            <input
              id="description"
              type="text"
              placeholder="What are you working on?"
              className="input input-bordered w-full"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={!!currentTracking}
            />
          </div>
          <div className="form-control">
            <label className="label" htmlFor="tags">
              <span className="label-text">Tags</span>
            </label>
            <div className="flex flex-wrap gap-2 mb-2">
              {tags.map((tag, index) => (
                <div key={index} className="badge badge-primary gap-2">
                  {tag}
                  <button
                    type="button"
                    onClick={() => removeTag(tag)}
                    className="btn btn-xs btn-circle btn-ghost"
                  >
                    <X size={12} />
                  </button>
                </div>
              ))}
            </div>
            <div className="relative">
              <input
                id="tags"
                ref={tagInputRef}
                type="text"
                placeholder="Add tags (e.g., #design)"
                className="input input-bordered w-full"
                value={tagInput}
                onChange={(e) => handleTagInput(e.target.value)}
                onKeyDown={handleTagKeyDown}
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                disabled={!!currentTracking}
              />
              {showSuggestions && filteredTags.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-base-100 border border-base-300 rounded-md shadow-lg max-h-60 overflow-auto">
                  {filteredTags.map((tag) => (
                    <div
                      key={tag.id}
                      className="px-4 py-2 hover:bg-base-200 cursor-pointer"
                      onMouseDown={() => handleTagSuggestionClick(tag.name)}
                    >
                      {tag.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text">Override hourly rate</span>
              <input
                type="checkbox"
                className="toggle toggle-primary"
                checked={overrideRate}
                onChange={(e) => setOverrideRate(e.target.checked)}
                disabled={!!currentTracking}
              />
            </label>
          </div>
          <div className="form-control">
            <label className="label" htmlFor="hourly-rate">
              <span className="label-text">Hourly Rate ($)</span>
            </label>
            <input
              id="hourly-rate"
              type="number"
              className="input input-bordered w-full"
              value={hourlyRate}
              onChange={(e) => setHourlyRate(parseFloat(e.target.value))}
              disabled={!overrideRate || !!currentTracking}
            />
          </div>
          {currentTracking ? (
            <div className="text-center">
              <div className="text-3xl font-bold mb-2">
                {formatTime(elapsedTime)}
              </div>
              <button
                onClick={handleStartStop}
                className="btn btn-primary btn-block"
              >
                <Square className="mr-2 h-4 w-4" /> Stop Tracking
              </button>
            </div>
          ) : (
            <button
              onClick={handleStartStop}
              className="btn btn-primary btn-block"
              disabled={!selectedClient || !selectedProject}
            >
              <Play className="mr-2 h-4 w-4" /> Start Tracking
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
