import { useState, useEffect } from "react";
import { Calendar, Clock, User, DollarSign } from "lucide-react";

interface SummaryData {
  hours: string;
  clients: number;
  projects: number;
  earnings: number;
}

type SummaryPeriod = "today" | "week" | "month" | "year" | "custom";

interface SummaryCardProps {
  getSummaryData: (period: SummaryPeriod) => Promise<SummaryData>;
  updateTrigger: number;
}

export function SummaryCard({
  getSummaryData,
  updateTrigger,
}: SummaryCardProps) {
  const [summaryPeriod, setSummaryPeriod] = useState<SummaryPeriod>("today");
  const [summaryData, setSummaryData] = useState<SummaryData>({
    hours: "0:00",
    clients: 0,
    projects: 0,
    earnings: 0,
  });

  useEffect(() => {
    fetchSummaryData();
  }, [summaryPeriod, updateTrigger]);

  async function fetchSummaryData() {
    const data = await getSummaryData(summaryPeriod);
    setSummaryData(data);
  }

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <h2 className="card-title text-lg">Summary</h2>
          <select
            className="select select-bordered w-full max-w-xs"
            value={summaryPeriod}
            onChange={(e) => setSummaryPeriod(e.target.value as SummaryPeriod)}
          >
            <option value="today">Today</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="year">This Year</option>
          </select>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="stat">
            <div className="stat-figure text-secondary">
              <Clock className="w-8 h-8" />
            </div>
            <div className="stat-title">Total Hours</div>
            <div className="stat-value">{summaryData.hours}</div>
          </div>
          <div className="stat">
            <div className="stat-figure text-secondary">
              <User className="w-8 h-8" />
            </div>
            <div className="stat-title">Clients Worked For</div>
            <div className="stat-value">{summaryData.clients}</div>
          </div>
          <div className="stat">
            <div className="stat-figure text-secondary">
              <Calendar className="w-8 h-8" />
            </div>
            <div className="stat-title">Projects Advanced</div>
            <div className="stat-value">{summaryData.projects}</div>
          </div>
          <div className="stat">
            <div className="stat-figure text-secondary">
              <DollarSign className="w-8 h-8" />
            </div>
            <div className="stat-title">Estimated Earnings</div>
            <div className="stat-value">${summaryData.earnings.toFixed(2)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
