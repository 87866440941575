// 1. User Preferences Model
export interface UserPreferences {
  user_id: string; // Clerk's user_id
  default_hourly_rate: number; // User's default hourly rate
  currency: string; // User's preferred currency (e.g., 'USD')
  name: string; // User's name
  created_at: string; // Timestamp of when preferences were created
  language: string; // User's preferred language (e.g., 'en', 'de')
}

// 2. Client Model
export interface Client {
  id: string; // Unique client ID (UUID)
  user_id: string; // Clerk's user_id
  name: string; // Client's name
  created_at: string; // Timestamp of when the client was created
}

// 3. Project Model
export interface Project {
  id: string; // Unique project ID (UUID)
  client_id: string; // ID of the client this project belongs to
  user_id: string; // Clerk's user_id
  name: string; // Project's name
  created_at: string; // Timestamp of when the project was created
}

// 4. Time Entry Model with Status Enum
export enum TimeEntryStatus {
  InProgress = "in_progress",
  Completed = "completed",
  Paused = "paused",
}

export interface TimeEntry {
  id: string; // Unique time entry ID (UUID)
  user_id: string; // Clerk's user_id
  project_id: string; // ID of the project this time entry belongs to
  description?: string; // Optional description of the work done
  start_time: string; // Start time of the time entry
  end_time?: string; // End time of the time entry (optional if in progress)
  hourly_rate?: number; // Optional specific hourly rate for this entry
  status: TimeEntryStatus; // Status of the time entry ('in_progress', 'completed', 'paused')
  created_at: string; // Timestamp of when the time entry was created
}

// 5. Tag Model
export interface Tag {
  id: string; // Unique tag ID (UUID)
  user_id: string; // Clerk's user_id
  name: string; // Name of the tag (e.g., 'Design', 'Development')
  created_at: string; // Timestamp of when the tag was created
}

// 6. Time Entry Tag Relationship Model
export interface TimeEntryTag {
  time_entry_id: string; // ID of the time entry
  tag_id: string; // ID of the tag
  user_id: string; // Clerk's user_id
}

export interface ReleaseNotes {
  id: string;
  header: string;
  content: string;
  created_at: string;
  published: boolean;
}
