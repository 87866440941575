import { Link } from "@tanstack/react-router";
import logo from "/src/assets/logo.svg";

export function Footer() {
  return (
    <footer className="footer p-10 bg-neutral text-neutral-content">
      <div>
        <Link to="/" className="flex items-center space-x-2">
          <img src={logo} alt="Chronos Logo" className="h-8 w-8" />
          <span className="font-bold text-xl">chronos</span>
        </Link>
        <p>Empowering your time management since 2024</p>
      </div>
      <div>
        <span className="footer-title">Services</span>
        <Link to="/" className="link link-hover">
          Home
        </Link>
        <Link to="/clients-projects" className="link link-hover">
          Clients & Projects
        </Link>
        <Link to="/reports" className="link link-hover">
          Reports
        </Link>
        <Link to="/settings" className="link link-hover">
          Settings
        </Link>
      </div>
      <div>
        <span className="footer-title">Company</span>
        <a className="link link-hover">About us</a>
        <a className="link link-hover">Contact</a>
        <a className="link link-hover">Jobs</a>
        <a className="link link-hover">Press kit</a>
      </div>
      <div>
        <span className="footer-title">Legal</span>
        <a className="link link-hover">Terms of use</a>
        <a className="link link-hover">Privacy policy</a>
        <a className="link link-hover">Cookie policy</a>
      </div>
    </footer>
  );
}
